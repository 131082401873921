import React from "react";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import "./CustomModal.css";

function CustomModal({
  children,
  open,
  onClose,
  title,
  isLoading,
  handleClose,
  form,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ paddingTop: 10 }}
    >
      <div className="custom-modal-container">
        <div className="custom-modal-banner">
          <h5>{title}</h5>
          <div className="custom-modal-banner-loader-container">
            {isLoading && (
              <CircularProgress
                size={20}
                style={{ position: "absolute", bottom: 30 }}
              />
            )}
          </div>
        </div>
        <div className="custom-modal-body">{children}</div>

        <div className="custom-modal-footer">
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // paddingTop: 40,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              color="error"
              onClick={handleClose}
              style={{
                marginRight: 10,
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="success"
              style={{
                backgroundColor: "#87ae5b",
              }}
              onClick={() => {
                form.handleSubmit();
              }}
              className="btn btn-primary"
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { CustomModal };
