import "./App.css";
import { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AccessMangementScreen from "./screens/AccessManagementScreen/index";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import EventScreen from "./screens/EventScreen";
import AccountScreen from "./screens/AccountScreen";
import UserScreen from "./screens/UserScreen";
import { AuthProvider } from "./contexts/AuthContext";

import SidebarNavigation from "./components/SidebarNavigation";
import TopNavigation from "./components/TopNavigation";
import GeofencesScreen from "./screens/GeofencesScreen";
import NotificationScreen from "./screens/NotificationScreen";
import ReportScreen from "./screens/ReportScreen";
import UnitScreen from "./screens/UnitScreen";

function App() {
  const defaultToken = JSON.parse(sessionStorage.getItem("session"))?.token;
  const [token, setToken] = useState(defaultToken || undefined);

  if (!token) {
    return (
      <AuthProvider>
        <LoginScreen setToken={setToken} />
      </AuthProvider>
    );
  }

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <SidebarNavigation />
          <div className="global-main">
            <TopNavigation />
            <div className="content">
              <Routes>
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/" element={<HomeScreen />} />
                <Route path="/events" element={<EventScreen />} />
                <Route
                  path="/admin/users"
                  element={<UserScreen setToken={setToken} />}
                />
                <Route
                  path="/account"
                  element={<AccountScreen setToken={setToken} />}
                />
                <Route path="/geofences" element={<GeofencesScreen />} />
                <Route path="/notifications" element={<NotificationScreen />} />
                <Route path="/reports" element={<ReportScreen />} />
                <Route path="/units" element={<UnitScreen />} />
              </Routes>
            </div>
          </div>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
