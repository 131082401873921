import React, { useEffect, useState, useCallback } from "react";
import { TopBanner } from "../components/TopBanner";
import DataTable from "../components/Datatable";
import { getNotificationsApi } from "../api/notification";
import useAuth from "../hooks/useAuth";
import "./css/EventScreen.css";
import NotificationsMenu from "../components/NotifactionsMenu";
import NotificationForm from "../components/NotificationForm";
import { SearchBar } from "../components/SearchBar";
import { debounce } from "lodash";

export default function NotificationScreen() {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [notificationData, setNotificationData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [selectedId, setSelectedId] = useState(0);

  const fetchNotifications = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        const notifications = await getNotificationsApi(
          auth.token,
          searchParams,
          targetPage,
          pageSize
        );

        const parsedData = notifications?.results.map((n) => ({
          created_at: n.created_at,
          eventName: n.event?.name,
          unitName: n.unit?.name,
          status: n.status_history?.[0]?.status?.verbose_name,
          action: (
            <NotificationsMenu
              data={notifications.results}
              open={open}
              setOpen={setOpen}
              id={n.id}
              notificationData={notificationData}
              setNotificationData={setNotificationData}
              setSelectedId={setSelectedId}
            />
          ),
        }));

        setData(parsedData);
        setTotalRows(notifications?.info?.count || 0);
        setNotificationData(
          notifications?.results.find((item) => item.id === selectedId) || {}
        );
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300), // 300ms debounce
    [targetPage, pageSize]
  );

  // Efecto para cargar datos
  useEffect(() => {
    fetchNotifications();
    return fetchNotifications.cancel; // Cancelar debounce al desmontar
  }, [fetchNotifications]);

  // Columnas de la tabla
  const columns = [
    { name: "Fecha", selector: (row) => row.created_at, sortable: true },
    { name: "Evento", selector: (row) => row.eventName, sortable: true },
    { name: "Unidad", selector: (row) => row.unitName, sortable: true },
    { name: "Estado", selector: (row) => row.status, sortable: true },
    { name: "Acciones", selector: (row) => row.action },
  ];

  return (
    <div>
      <TopBanner sectionName="Notificaciones" />
      <SearchBar
        searchItems={[
          // Aquí puedes añadir los filtros que necesites
        ]}
        mainFilter="unitName"
        setSearchParams={setSearchParams}
      />
      <div className="event_container-table">
        <DataTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          totalRows={totalRows}
          onChangePage={setTargetPage}
          onChangePageSize={(size) => {
            setPageSize(size);
            setTargetPage(1); // Reinicia a la primera página
          }}
        />
      </div>
      <NotificationForm
        open={open}
        setOpen={setOpen}
        setRequestToggle={() => fetchNotifications()}
        requestToggle={false}
        token={auth.token}
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
    </div>
  );
}
