import { request } from "../utils/network";
import { endpoint } from "../utils/constants";

async function getNotificationsApi(token, searchParams, targetPage, pageSize) {
  let urlParams = {
    page: targetPage,
    page_size: pageSize,
  };

  // if (Object.entries(searchParams).length > 0) {
  //   let params = {
  //     event: {
  //       name: searchParams.eventName,
  //     },
  //     // unit: {
  //     //   name: searchParams.unit,
  //     // },
  //   };

  //   urlParams.search_params = JSON.stringify(params);
  // }

  try {
    let notifications = await request({
      route: "notifications",
      method: "GET",
      token,
      customParams: urlParams,
    });
    return notifications;
  } catch (error) {
    console.log(error);
  }
}

async function updateNotificationStatusApi({ token, id, status, comment }) {
  try {
    let data = {
      notification_id: id,
      status_id: status,
      comment,
    };

    const response = await request({
      route: `notifications/change_status`,
      method: "PUT",
      token,
      data,
    });

    return response;
  } catch (error) {
    throw error;
  }
}

export { getNotificationsApi, updateNotificationStatusApi };
