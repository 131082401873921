import { GoogleMap, LoadScript, Polygon, Marker } from "@react-google-maps/api";
import { useState, useEffect } from "react";

export default function Map({
  polygonColor,
  polygonCoords,
  polygonDrawing,
  onClick,
  onMouseUp,
  onDragEnd,
  onLoad,
  markerCoords,
  customZoom,
  style,
}) {
  const mapContainerStyle = {
    height: style?.height || "45vh",
    width: style?.width || "100%",
    borderRadius: style?.borderRadius || 20,
    boxShadow: "0 10px 10px var(--container-shadow-color)",
  };
  const polygonOptions = {
    fillColor: polygonColor,
    fillOpacity: 0.35,
    strokeColor: "black",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: polygonDrawing,
    draggable: polygonDrawing,
    editable: polygonDrawing,
    zIndex: 1,
  };

  // this is to prevent map refresh center
  const [center] = useState({ lat: 18.735693, lng: -70.162651 });
  const zoom = 9;
  const mapOptions = {
    gestureHandling: "greedy",
  };
  const [MapCenter, setMapCenter] = useState(null);

  const handleLoad = (map) => {
    setMapCenter(map);
  };

  useEffect(() => {
    if (MapCenter && polygonCoords?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      if (Array.isArray(polygonCoords[0])) {
        // polygonCoords is a List of List
        polygonCoords.forEach((coords) => {
          coords.forEach(({ lat, lng }) => {
            bounds.extend(new window.google.maps.LatLng(lat, lng));
          });
          MapCenter.fitBounds(bounds);
        });
      } else if ("id" in polygonCoords[0]) {
        // polygonCoords is a Object List
        polygonCoords.forEach(({ lat, lng }) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        MapCenter.fitBounds(bounds);
      }
    }
  }, [MapCenter, polygonCoords]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      language="es"
    >
      <GoogleMap
        onLoad={handleLoad}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        onClick={onClick}
        options={mapOptions}
      >
        <Polygon
          options={polygonOptions}
          paths={polygonCoords}
          onMouseUp={onMouseUp}
          onDragEnd={onDragEnd}
          onLoad={onLoad}
        />

        <Marker position={markerCoords} />
      </GoogleMap>
    </LoadScript>
  );
}
