import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { AiOutlineCheckCircle } from "react-icons/ai";
// import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { FaRegTimesCircle } from "react-icons/fa";
import "./index.css";

function ModalMessage({ msg, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 600,
        },
      }}
    >
      <div className="MessageModal-container">
        <Confetti
          width={930}
          height={360}
          tweenDuration={10000}
          // numberOfPieces={600}
          recycle={false}
        />
        <div>
          <FaRegTimesCircle
            size={18}
            color="darkred"
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              cursor: "pointer",
            }}
            onClick={() => handleClose()}
          />
          <h4 style={{ whiteSpace: "nowrap" }}>
            ¡Bienvenidos a la actualización del Portal de Gestión de Eventos
            INTRANT!
          </h4>
          <p>En la actualización podra apreciar:</p>
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            <li>
              <AiOutlineCheckCircle color="green" /> Modulo de Analítica
            </li>
            <li>
              <AiOutlineCheckCircle color="green" /> Modulo de asignación de
              Roles
            </li>
            <li>
              <AiOutlineCheckCircle color="green" /> Nuevos informes exportables
            </li>
            <li>
              <AiOutlineCheckCircle color="green" /> Mapa Termico de Incidencia
            </li>
            <li>
              <AiOutlineCheckCircle color="green" /> Correcciones de Bugs
            </li>
          </ul>
          <div style={{ marginTop: 24 }}>
            <p>
              Ante cualquier soporte comunicarse a nuestros canales de
              servicios:
            </p>
            <div style={{ width: "100%", display: "flex", gap: 10 }}>
              <span>
                <b> cmtc@lebuscogps.com.do</b>
              </span>
              <span>809-274-5650. Ext: 404</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { ModalMessage };
