import React from "react";
import { getGeofencesApi } from "../../api/geofence";
import { getUnitsApi } from "../../api/unit";
import useAuth from "../../hooks/useAuth";
import { CustomModal } from "../CustomModal";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./EventForm.css";
import { ItemList } from "../ItemList";
import { ListItem } from "../ListItem";
import { createEventApi } from "../../api/event";
import {
  FaAngleDoubleRight,
  FaAngleRight,
  FaAngleLeft,
  FaSearch,
} from "react-icons/fa";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";

function EventForm({ open, setOpen, setRequestToggle, searchTimeOut }) {
  const { auth } = useAuth();
  const [geofences, setGeofences] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [loadingUnits, setLoadingUnits] = React.useState(false);
  const [unitReqToggle, setUnitReqToggle] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        let storedUnits = await getUnitsApi(auth.token, 1, 99999);

        let storedGeofences = await getGeofencesApi(auth.token, 1, 99999);

        if (storedGeofences.detail) {
          throw new Error(`${storedGeofences?.detail[0].msg}`);
        }

        setGeofences(storedGeofences.results);

        let customUnits = [];
        storedUnits.results.map((unit) => {
          customUnits.push({
            ...unit,
            selected: false,
            position: "left",
          });
        });

        setUnits(customUnits);
        setLoadingUnits(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [unitReqToggle]);

  const handleClose = () => {
    setOpen(false);
  };

  const eventForm = useFormik({
    initialValues: {
      eventName: "",
      geofence: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      eventName: Yup.string().required("Ingrese un nombre para el evento"),
      geofence: Yup.string().required("Seleccione una geocerca"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let data = {
        name: values.eventName,
        active: true,
        geofence_id: values.geofence,
        units: units
          .filter((unit) => unit.position == "right")
          .map((unit) => unit.id),
      };

      try {
        await createEventApi(auth.token, data);
        setRequestToggle((state) => !state);
      } catch (error) {
        console.log(error);
      }

      let arr = [...units];

      arr.forEach((unit) => {
        unit.position = "left";
        unit.selected = false;
      });

      setUnits(arr);
      setSearchValue("");
      resetForm();
      handleClose();
    },
  });

  const selectUnit = (unitName, position) => {
    let newUnits = [...units];
    let sideUnits = newUnits.filter((unit) => unit.position == position);

    const index = sideUnits?.findIndex((item) => item.name === unitName);

    if (sideUnits[index].selected == true) {
      sideUnits[index].selected = false;
    } else {
      sideUnits[index].selected = true;
    }

    setUnits(newUnits);
  };

  const selectAll = (direction) => {
    let newUnits = [...units];

    let arr = [];

    searchedUnits.filter((unit) => {
      if (unit.position == direction) {
        arr.push(units.findIndex((item) => item.id == unit.id));
      }
    });

    arr.forEach((unitIndex) => {
      newUnits[unitIndex].selected = true;
    });

    setUnits(newUnits);
  };

  const discardAll = (direction) => {
    let newUnits = [...units];

    let arr = [];

    searchedUnits.filter((unit) => {
      if (unit.position == direction) {
        arr.push(units.findIndex((item) => item.id == unit.id));
      }
    });

    arr.forEach((unitIndex) => {
      newUnits[unitIndex].selected = false;
    });

    setUnits(newUnits);
  };

  const moveUnits = (direction) => {
    let newUnits = [...units];
    let arr = [];
    switch (direction) {
      case "left":
        newUnits.filter((unit) => {
          if (unit.position == "right" && unit.selected == true) {
            arr.push(units.findIndex((item) => item.id == unit.id));
          }
        });
        arr.forEach((unitIndex) => {
          newUnits[unitIndex].position = "left";
          newUnits[unitIndex].selected = false;
        });
        break;
      case "right":
        newUnits.filter((unit) => {
          if (unit.position == "left" && unit.selected == true) {
            arr.push(units.findIndex((item) => item.id == unit.id));
          }
        });

        arr.forEach((unitIndex) => {
          newUnits[unitIndex].position = "right";
          newUnits[unitIndex].selected = false;
        });
        break;
      default:
        break;
    }
    setUnits(newUnits);
  };

  React.useEffect(
    () => {
      const delayInput = setTimeout(() => {
        // searchForm.handleSubmit();

        setUnitReqToggle(!unitReqToggle);
      }, searchTimeOut || 1000);

      return () => {
        clearTimeout(delayInput);
        // setLoadingUnits(true);
      };
    },
    [
      /*searchValue*/
    ]
  );

  const searchedUnits = units?.filter((unit) =>
    unit.name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Nuevo evento"
      form={eventForm}
      handleClose={handleClose}
    >
      <div className="EventForm-container">
        <div className="EventForm-row">
          <div className="EventForm-block">
            <p>Nombre</p>
            <input
              placeholder="Ej. Zona restringida"
              value={eventForm.values.eventName}
              onChange={(e) => {
                eventForm.setFieldValue("eventName", e.target.value);
              }}
            />
            <span className="EventForm-input-error">
              {eventForm.errors.eventName}
            </span>
          </div>
          <div className="EventForm-block">
            <p>Geocerca</p>
            <select
              onChange={(e) =>
                eventForm.setFieldValue("geofence", e.target.value)
              }
            >
              <option>{"<Selecciona una geocerca >"}</option>
              {geofences?.map((geofence) => (
                <option key={geofence.id} value={geofence.id}>
                  {geofence.name}
                </option>
              ))}
            </select>
            <span className="EventForm-input-error">
              {eventForm.errors.geofence}
            </span>
          </div>
        </div>
        <div className=" EventForm-row--second">
          <div className="EventForm-block--units">
            <div className="EventForm-block--units-labels">
              <p>Todas las unidades</p>
              <span>
                Unidades seleccionadas{" "}
                {`(${
                  units.filter(
                    (unit) => unit.selected && unit.position == "left"
                  ).length
                })`}{" "}
              </span>
            </div>

            <div className="EventForm-unit-container">
              <p>{loadingUnits && "loading..."}</p>
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                type="search"
                placeholder="Buscar..."
              />
              <FaSearch
                style={{
                  //position: "fixed",
                  fontSize: 12,
                  color: "grey",
                  marginTop: -50,
                  marginLeft: 10,
                }}
              />
              <div
                className="EventForm-unit-container-body "
                style={{ paddingTop: 0 }}
              >
                {searchedUnits
                  .filter((unit) => unit.position == "left")
                  ?.map((unit, index) => (
                    <div
                      key={index}
                      className={`EventForm-unit-container-body-item ${
                        unit.selected && "selected"
                      }`}
                      onClick={(e) => {
                        if (e.nativeEvent.shiftKey == false) {
                          selectUnit(e.target.innerText, "left");
                        } else {
                          let lastSelectedItem = searchedUnits
                            .filter((unit) => unit.position == "left")
                            .map((unit) => unit.selected)
                            .lastIndexOf(true);
                          let targetItem = searchedUnits
                            .filter((unit) => unit.position == "left")
                            .findIndex(
                              (unit) => unit.name == e.target.innerText
                            );

                          if (lastSelectedItem == -1) {
                            alert(
                              "Selecciona un punto de partida antes de usar Shift"
                            );
                          } else {
                            for (
                              let i = lastSelectedItem + 1;
                              i <= targetItem;
                              i++
                            ) {
                              selectUnit(
                                searchedUnits.filter(
                                  (unit) => unit.position == "left"
                                )[i].name,
                                "left"
                              );
                            }
                          }
                        }
                      }}
                    >
                      {unit.name}
                    </div>
                  ))}
              </div>
            </div>
            <div
              onClick={() => selectAll("left")}
              className="EventForm-unit-selection--all"
            >
              <p>Seleccionar todas</p>
            </div>
            <div
              onClick={() => discardAll("left")}
              className="EventForm-unit-selection--all discard"
            >
              <p>Descartar Selección</p>
            </div>
          </div>
          <div className=" EventForm-block--units--second">
            <div
              className="EventForm-units-cross EventForm-units-cross--first"
              onClick={() => moveUnits("right")}
            >
              <FaAngleRight />
            </div>
            <div
              className="EventForm-units-cross EventForm-units-cross--second"
              onClick={() => moveUnits("left")}
            >
              <FaAngleLeft />
            </div>
            {/* <div className="EventForm-units-cross">
              <FaAngleLeft />
            </div>
            <div className="EventForm-units-cross">
              <FaAngleLeft />
            </div> */}
          </div>
          <div className="EventForm-block--units">
            <div className="EventForm-block--units-labels">
              <p>Unidades seleccionadas</p>
              <span>
                Unidades seleccionadas{" "}
                {`(${
                  units.filter(
                    (unit) => unit.selected && unit.position == "right"
                  ).length
                })`}{" "}
              </span>
            </div>
            <div className="EventForm-unit-container">
              <div className="EventForm-unit-container-body EventForm-unit-container-body--right">
                {units
                  ?.filter((unit) => unit.position == "right")
                  .map((unit, index) => (
                    <div
                      key={index}
                      className={`EventForm-unit-container-body-item ${
                        unit.selected && "selected"
                      }`}
                      onClick={(e) => {
                        if (e.nativeEvent.shiftKey == false) {
                          selectUnit(e.target.innerText, "right");
                        } else {
                          let lastSelectedItem = units
                            .filter((unit) => unit.position == "right")
                            .map((unit) => unit.selected)
                            .lastIndexOf(true);
                          let targetItem = units
                            .filter((unit) => unit.position == "right")
                            .findIndex(
                              (unit) => unit.name == e.target.innerText
                            );

                          if (lastSelectedItem == -1) {
                            alert(
                              "Selecciona un punto de partida antes de usar Shift"
                            );
                          } else {
                            for (
                              let i = lastSelectedItem + 1;
                              i <= targetItem;
                              i++
                            ) {
                              selectUnit(
                                searchedUnits.filter(
                                  (unit) => unit.position == "right"
                                )[i].name,
                                "right"
                              );
                            }
                          }
                        }
                      }}
                    >
                      {unit.name}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className="EventForm-unit-selection--all"
              onClick={() => selectAll("right")}
            >
              <p>Seleccionar todas</p>
            </div>
            <div
              onClick={() => discardAll("right")}
              className="EventForm-unit-selection--all discard"
            >
              <p>Descartar Selección</p>
            </div>
          </div>
        </div>
        {/* <div className="EventForm-block">
          <TextField
            autoFocus
            label="Nombre evento"
            variant="outlined"
            color="warning"
            className="form-control"
            placeholder="nuevo evento..."
            value={form.values.eventName}
            onChange={(e) => {
              form.setFieldValue("eventName", e.target.value);
            }}
          />
          {form.errors.eventName && (
            <span className="EventForm-error">{form.errors.eventName}</span>
          )}
        </div>
        <div className="EventForm-block EventForm-block--source">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel color="warning">Rol</InputLabel>
            <Select
              className="form-control"
              label="Rol"
              color="warning"
              value={form.values.geofence}
              onChange={(e) => {
                
                form.setFieldValue("geofence", e.target.value);
              }}
            >
              <MenuItem value="" disabled>
                {"< seleccione una geocerca >"}
              </MenuItem>
              {geofences?.map((geofence) => (
                <MenuItem key={geofence.id} value={geofence.id}>
                  {geofence.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {form.errors.geofence && (
            <span className="EventForm-error">{form.errors.geofence}</span>
          )}
        </div>
        <div className="EventForm-block EventForm-block--destination">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel color="warning">Unidades</InputLabel>
            <Select
              className="form-control"
              color="warning"
              label="Unidades"
              value={form.values.unit}
              onChange={(e) => {
           
                form.setFieldValue("unit", e.target.value);
                selectUnit(e.target.value);
              }}
            >
              {units?.map((unit, index) => (
                <MenuItem key={index} value={unit.id}>
                  {unit.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="EventForm-block-summary">
            <ItemList title="Unidades seleccionadas">
              {selectedUnits?.map((unit) => (
                <div key={unit.id}>
                  <ListItem>
                    {unit.id}-{unit.name}
                  </ListItem>
                </div>
              ))}
            </ItemList>
          </div>
        </div> */}
      </div>
    </CustomModal>
  );
}

export default EventForm;
