import { request } from "../utils/network";

async function getUnitsApi(token, targetPage, pageSize, searchParams) {
  let urlParams = {
    page: targetPage,
    page_size: pageSize,
  };

  if (searchParams && Object.entries(searchParams).length > 0) {
    urlParams.search_params = JSON.stringify(searchParams);
  }

  try {
    let units = await request({
      route: "units",
      method: "GET",
      token,
      customParams: urlParams,
    });
    return units;
  } catch (error) {
    console.log(error);
  }
}

export { getUnitsApi };
