import { endpoint } from "../utils/constants";
import { request } from "../utils/network";

async function getGeofencesApi(token, targetPage, pageSize, searchParams) {
  let urlParams = {
    page: targetPage,
    page_size: pageSize,
  };

  if (searchParams && Object.entries(searchParams).length > 0) {
    urlParams.search_params = JSON.stringify(searchParams);
  }

  try {
    let geofences = await request({
      route: "geofences",
      method: "GET",
      token,
      customParams: urlParams,
    });

    return geofences;
  } catch (error) {
    console.log(error);
  }
}

async function createGeofenceApi(data, token) {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(`${endpoint}/geofences`, options);
    const result = await response.json();

    return result;
  } catch (error) {
    throw error;
  }
}

async function updateGeofenceApi(id, data, token) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(`${endpoint}/geofences/${id}`, options);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export { getGeofencesApi, createGeofenceApi, updateGeofenceApi };
