import { request } from "../utils/network";
import { endpoint } from "../utils/constants";

export async function getUsersApi(token, targetPage, pageSize, searchParams) {
  let urlParams = {
    page: targetPage,
    page_size: pageSize,
  };

  if (Object.entries(searchParams).length > 0) {
    urlParams.search_params = JSON.stringify(searchParams);
  }

  try {
    let units = await request({
      route: "users",
      method: "GET",
      token,
      customParams: urlParams,
    });
    return units;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserApi(id) {
  try {
    const response = await fetch(`${endpoint}/users/${id}`);
    const result = response.json();
    if (result.detail) return new Error(result.detail[0].msg);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function createUserApi(data, token) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: `${token}` },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${endpoint}/auth/sign_up`, options);
    const result = await response.json();

    if (result.detail) return new Error(result.detail[0].msg);

    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateUserApi(id, data, token) {
  const options = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: `${token}` },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${endpoint}/users/${id}`, options);

    const result = await response.json();

    // if (result.detail) return new Error(result.detail[0].msg);

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
